import {JOBPOST, RESET} from '../../definitions';
import {
  JobPostAction,
  JobPostGet,
  JobPostGetLoading,
  JobPostInitialState,
} from '../../../config/interface';

const initialState: JobPostInitialState = {
  action: '',
  data: [],
  isLoading: false,
};

export default (state = initialState, action: JobPostAction) => {
  switch (action.type) {
    case JOBPOST.GET:
      return {
        ...state,
        action: action.type,
        data: (action as JobPostGet).data,
      };
    case JOBPOST.GET_LOADING:
      return {
        ...state,
        action: action.type,
        data_detail: (action as JobPostGetLoading).isLoading,
      };
    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
