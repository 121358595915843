import {RESET, ATTENDANCESUBMISSIONREPORTS} from '../../definitions';
import {settings} from '../../../config/config';
import {cookie} from '../../../utils';

const getSetting = cookie.getSubmFilter();
const initialSetting = getSetting
  ? JSON.parse(getSetting)
  : settings.filterReport;

const initialFilterDetail = {
  date: '',
  hasSubmitted: 0,
};

const initialState = {
  data: [],
  data_detail: [],
  isLoading: false,
  filter: {
    start: initialSetting.start,
    end: initialSetting.end,
  },
  isLoadingFilter: false,
  filterDetail: initialFilterDetail,
  isLoadingFilterDetail: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ATTENDANCESUBMISSIONREPORTS.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
          data_detail: [],
          filterDetail: initialFilterDetail,
        },
      );

    case ATTENDANCESUBMISSIONREPORTS.GET_DETAIL:
      return Object.assign(
        {},
        {
          ...state,
          data_detail: action.data,
        },
      );

    case ATTENDANCESUBMISSIONREPORTS.FILTER:
      return Object.assign(
        {},
        {
          ...state,
          filter: action.data,
        },
      );

    case ATTENDANCESUBMISSIONREPORTS.FILTER_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoadingFilter: action.isLoadingFilter,
        },
      );

    case ATTENDANCESUBMISSIONREPORTS.FILTER_DETAIL:
      return Object.assign(
        {},
        {
          ...state,
          filterDetail: action.data,
        },
      );

    case ATTENDANCESUBMISSIONREPORTS.FILTER_DETAIL_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoadingFilterDetail: action.isLoadingFilter,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
