import {MASTER} from '../../definitions';
import {storage, logger} from '../../../utils';
import api from '../../../api';
import {
  JobPostionItemRes,
  DepartmentRes,
  PositionRes,
  JobPostion,
  JobPostionItem,
} from '../../../config/interface';

const getMaster = () => {
  return (dispatch: any) => {
    // Dispatch temporary data from localstorage
    dispatch({type: MASTER.GET_LOADING, isLoading: true});

    let temp = storage.getMaster();
    if (temp) {
      dispatch({
        type: MASTER.GET,
        data: JSON.parse(temp),
      });
    }

    // Api call
    Promise.all([
      api.getJobPositions(),
      api.getDepartments(),
      api.getPositions(),
    ])
      .then((result: any) => {
        const jobPstAll = result[0].data.reduce(
          (obj: string[], item: JobPostionItemRes) => {
            obj[parseInt(item.id)] =
              item.department.name + ' - ' + item.position.name;
            return obj;
          },
          {},
        );

        const jobPst: JobPostion = result[0].data.map(
          (item: JobPostionItemRes) => ({
            id: parseInt(item.id),
            positionId: item.position_id,
            departmentId: item.department_id,
            position: {
              id: item.position.id,
              name: item.position.name,
            },
            department: {
              id: item.department.id,
              name: item.department.name,
            },
            userCount: item.user_count,
          }),
        );

        const departments = result[1].data.map((dt: DepartmentRes) => {
          const jobPstFiltered = jobPst.filter(
            (i: JobPostionItem) => dt.id === i.departmentId,
          );

          return {
            id: dt.id,
            name: dt.name,
            jobPst: jobPstFiltered.map((i) => ({
              id: i.id,
              name: i.position.name,
              userCount: i.userCount,
            })),
          };
        });

        const positions = result[2].data.map((dt: PositionRes) => ({
          id: dt.id,
          name: dt.name,
        }));

        const jobPstPerDprt = departments.reduce(
          (obj: string[], item: JobPostionItemRes) => {
            obj[parseInt(item.id)] = result[0].data
              .filter(
                (i: JobPostionItemRes) => i.department_id === parseInt(item.id),
              )
              .reduce((obj: string[], x: JobPostionItemRes) => {
                obj[parseInt(x.id)] = x.position.name;
                return obj;
              }, {});

            return obj;
          },
          {},
        );

        const master = {
          jobPstAll,
          departments,
          positions,
          jobPstPerDprt,
          jobPst,
        };

        storage.setMaster(master);

        return dispatch({
          type: MASTER.GET,
          data: master,
        });
      })
      .then(() => dispatch({type: MASTER.GET_LOADING, isLoading: false}))
      .catch((err) => {
        dispatch({type: MASTER.GET_LOADING, isLoading: false});
        logger(
          err,
          'error',
          'getMaster',
          false,
          err && err.response && err.response.data,
        );
      });
  };
};

export {getMaster};
