import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {store, history} from './store';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import 'draft-js/dist/Draft.css';

// Import main views
import Wrapper from './views/Wrapper';

if ((process.env.REACT_APP_ENV as string).trim() !== 'development')
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION,
    maxBreadcrumbs: 20,
    ignoreErrors: ['Network Error'],
  });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Wrapper />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// Register service worker in production only
serviceWorker.unregister();
