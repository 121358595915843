import moment from 'moment';
import {storage} from '../../utils';
import 'moment/locale/id';
import {settings} from '../../config/config';
import {dateFormat, dateFormatCorrect} from '../../config/constant';

// show date
export const showDate = (
  date: Date,
  isLong: boolean = true,
  isUtc: boolean = true,
) => {
  if (!date) return '-';

  const lang = storage.getLang();
  moment.locale(lang ? lang : settings.lang);
  if (isUtc)
    return moment
      .utc(date)
      .local()
      .format(isLong ? 'LL' : 'll');
  return moment(date).format(isLong ? 'LL' : 'll');
};

export const showDateTime = (
  date: Date,
  isLong: boolean = false,
  isUtc: boolean = true,
) => {
  if (!date) return '-';

  const lang = storage.getLang();
  moment.locale(lang ? lang : settings.lang);
  if (isUtc)
    return moment
      .utc(date)
      .local()
      .format(isLong ? dateFormat : dateFormatCorrect);
  return moment(date).format(isLong ? dateFormat : dateFormatCorrect);
};

export const showClock = () => {
  const lang = storage.getLang();
  moment.locale(lang ? lang : settings.lang);
  return moment().format('llll');
};

export const showTime = (date: Date, isUtc: boolean = true) => {
  const lang = storage.getLang();
  moment.locale(lang ? lang : settings.lang);

  if (isUtc) return moment.utc(date).local().format('HH:mm');
  return moment(date).format('HH:mm');
};

export const showDuration = (
  startTime: Date | null | undefined | string,
  isUtc: boolean = true,
) => {
  if (!startTime) return '-';

  const lang = storage.getLang();
  moment.locale(lang ? lang : settings.lang);

  const start = isUtc ? moment.utc(startTime).local() : moment(startTime);
  const end = moment();
  const diff = end.diff(start);
  const d = moment.duration(diff);

  return Math.floor(d.asHours()) + moment.utc(diff).format(':mm:ss');
};
