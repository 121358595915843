import {RESET} from '../../definitions';
import {storage, cookie} from '../../../utils';

const signout = () => {
  return (dispatch: any) => {
    return new Promise((resolve) => {
      storage.removeToken();
      storage.removeAdminUsers();
      storage.removeAttendSubmissionReports();
      storage.removeAttendApprovalReports();
      storage.removeAttendDetailReport();
      storage.removeAttendSummaryReport();
      storage.removeApprovalSettings();
      storage.removeProfile();
      storage.removeEmployee();
      storage.removeMaster();
      cookie.removeAppFilter();
      cookie.removeSubmFilter();
      cookie.removeBroadcastFilter();
      cookie.removeAttDetFilter();
      cookie.removeAttSumFilter();

      dispatch({type: RESET.ALL});
      resolve();
    });
  };
};

export {signout};
