import {ALERT, RESET} from '../../definitions';
import {
  AlertAction,
  AlertShow,
  AlertInitialState,
} from '../../../config/interface';

const initialState: AlertInitialState = {
  isShowing: false,
  message: '',
  values: {},
  type: 'warning',
};

export default (state = initialState, action: AlertAction) => {
  switch (action.type) {
    case ALERT.SHOW:
      return Object.assign(
        {},
        {
          ...(action as AlertShow).alert,
          isShowing: true,
        },
      );

    case ALERT.HIDE:
      return initialState;

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
