import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment, {Moment} from 'moment';
import {isNull} from 'lodash';

const isEmpty = (value: string) => {
  return typeof value === 'undefined' || value === undefined || value === ''
    ? true
    : false;
};

export const required = (value: string) => {
  return isEmpty(value) ? (
    <FormattedMessage
      id="validationRequired"
      defaultMessage="validationRequired"
    />
  ) : undefined;
};

export const requiredArray = (value: Array<any>) => {
  return !Array.isArray(value) || value.length < 1 ? (
    <FormattedMessage
      id="validationRequired"
      defaultMessage="validationRequired"
    />
  ) : undefined;
};

export const requiredFile = (value: Array<File>) => {
  return value.length < 1 ||
    value.some((item: File) => {
      return typeof item.size !== 'number';
    }) ? (
    <FormattedMessage
      id="validationRequired"
      defaultMessage="validationRequired"
    />
  ) : undefined;
};
export const requiredFileFeatured = (value: Array<any>) => {
  return value.length < 1 || value.every((item: any) => !item.isFeatured) ? (
    <FormattedMessage
      id="validationFileFeatured"
      defaultMessage="validationFileFeatured"
    />
  ) : undefined;
};

interface DateRange {
  startDate?: Moment;
  endDate?: Moment;
}

export const requiredStartdt = (value: DateRange) => {
  return !moment.isMoment(value.startDate) ? (
    <FormattedMessage
      id="validationStartDateRequired"
      defaultMessage="validationStartDateRequired"
    />
  ) : undefined;
};

export const requiredEnddt = (value: DateRange) => {
  return !moment.isMoment(value.endDate) ? (
    <FormattedMessage
      id="validationEndDateRequired"
      defaultMessage="validationEndDateRequired"
    />
  ) : undefined;
};

export const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
    <FormattedMessage
      id="validationInvalidEmail"
      defaultMessage="validationInvalidEmail"
    />
  ) : undefined;

export const phone = (value: string) => {
  return value &&
    /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)[-][987654321]\d{5,13}$/i.test(
      value,
    ) ? undefined : (
    <FormattedMessage
      id="validationInvalidPhone"
      defaultMessage="validationInvalidPhone"
    />
  );
};

const exactLength = (limit: number) => (value: string) => {
  return value && value.length !== limit ? (
    <FormattedMessage
      id="validationExactLength"
      defaultMessage="validationExactLength"
      values={{value: limit}}
    />
  ) : undefined;
};

export const isNumber = (value: number) =>
  value && isNaN(value) ? (
    <FormattedMessage
      id="validationIsNumber"
      defaultMessage="validationIsNumber"
    />
  ) : undefined;

export const passwordRegex = (value: string) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g.test(value) ? (
    <FormattedMessage
      id="validationPasswordReq"
      defaultMessage="validationPasswordReq"
    />
  ) : undefined;
};

export const passwordsMustMatch = (value: string, allValues: any) => {
  return allValues.new_password !== value ? (
    <FormattedMessage
      id="validationPasswordMatch"
      defaultMessage="validationPasswordMatch"
    />
  ) : undefined;
};

export const newPasswordsCantMatch = (value: string, allValues: any) => {
  return allValues.old_password === value ? (
    <FormattedMessage
      id="validationPasswordNew"
      defaultMessage="validationPasswordNew"
    />
  ) : undefined;
};

export const publishDateTimeMoreThanToday = (value: string) => {
  return moment(value).isBefore(moment().format('YYYY-MM-DD')) ? (
    <FormattedMessage
      id="validationPublishDateIsAfterToday"
      defaultMessage="validationPublishDateIsAfterToday"
    />
  ) : undefined;
};

export const validUntilMoreThanToday = (value: string) => {
  return moment(value).isBefore(moment().format('YYYY-MM-DD')) ? (
    <FormattedMessage
      id="validationValidUntilIsAfterToday"
      defaultMessage="validationValidUntilIsAfterToday"
    />
  ) : undefined;
};

export const validDateTimeMoreThanToday = (value: string) => {
  return moment().diff(moment(value), 'h') < -23 ? (
    <FormattedMessage
      id="validationValidDateIsMinAfterToday"
      defaultMessage="validationValidDateIsMinAfterToday"
    />
  ) : undefined;
};

export const validUntilMoreThanPublishDate = (
  value: string,
  allValues: any,
) => {
  return moment(value).isBefore(allValues['publishDate']) ? (
    <FormattedMessage
      id={'validationPublishDateIsAfterValidUntil'}
      defaultMessage={'validationPublishDateIsAfterValidUntil'}
    />
  ) : undefined;
};

export const passwordUpLow8 = (value: string) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g.test(value) ? (
    <FormattedMessage
      id="validationPasswordUpLowNum8"
      defaultMessage="validationPasswordUpLowNum8"
    />
  ) : undefined;
};

export const passwordUpLowSymbol8 = (value: string) => {
  return !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{}|']).{8,50}/g.test(
    value,
  ) ? (
    <FormattedMessage
      id="validationPasswordUpLowNumSymbol8"
      defaultMessage="validationPasswordUpLowNumSymbol8"
    />
  ) : undefined;
};

export const minLength = (min: number) => (value: string) => {
  return value && value.length < min ? (
    <FormattedMessage
      id="validationMinLength"
      defaultMessage="validationMinLength"
      values={{value: min}}
    />
  ) : undefined;
};

const maxLength = (max: number) => (value: string) => {
  return value && value.length > max ? (
    <FormattedMessage
      id="validationMaxLength"
      defaultMessage="validationMaxLength"
      values={{value: max}}
    />
  ) : undefined;
};

export const exactLength16 = exactLength(16);
export const exactLength3 = exactLength(3);
export const exactLength4 = exactLength(4);

export const minLength4 = minLength(4);
export const minLength5 = minLength(5);
export const minLength20 = minLength(20);
export const maxLength100 = maxLength(100);
export const maxLength50 = maxLength(50);
export const maxLength150 = maxLength(150);
export const maxLength300 = maxLength(300);
