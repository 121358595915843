import {RESET, ATTENDANCEDETAILSREPORT} from '../../definitions';
import {
  AttendanceDetailReportInitialState,
  AttendanceDetailReportAction,
  AttendanceDetailReportGet,
  AttendanceDetailReportGetLoading,
  AttendanceDetailReportFilter,
  AttendanceDetailReportExportGetLoading,
  AttendanceDetailPatchLoading,
  AttendanceDetailPatchSuccess,
  AttendanceDetailPatchFailed,
} from '../../../config/interface';
import {settings} from '../../../config/config';
import {cookie} from '../../../utils';

const getSetting = cookie.getAttDetFilter();
const initialSetting = getSetting
  ? JSON.parse(getSetting)
  : settings.filterAttendanceDetailReport;

const initialState: AttendanceDetailReportInitialState = {
  action: '',
  data: [],
  isLoading: false,
  filter: {
    year: initialSetting.year,
    month: initialSetting.month,
    employeeCode: initialSetting.employeeCode,
    status: initialSetting.status,
  },
  exportIsLoading: false,
  patchLoading: false,
};

export default (state = initialState, action: AttendanceDetailReportAction) => {
  switch (action.type) {
    case ATTENDANCEDETAILSREPORT.GET:
      return {
        ...state,
        action: (action as AttendanceDetailReportGet).type,
        data: (action as AttendanceDetailReportGet).data,
      };

    case ATTENDANCEDETAILSREPORT.GET_LOADING:
      return {
        ...state,
        action: (action as AttendanceDetailReportGetLoading).type,
        isLoading: (action as AttendanceDetailReportGetLoading).isLoading,
      };

    case ATTENDANCEDETAILSREPORT.FILTER:
      return {
        ...state,
        action: (action as AttendanceDetailReportFilter).type,
        filter: (action as AttendanceDetailReportFilter).data,
      };

    case ATTENDANCEDETAILSREPORT.GET_EXPORT_LOADING:
      return {
        ...state,
        action: (action as AttendanceDetailReportExportGetLoading).type,
        exportIsLoading: (action as AttendanceDetailReportExportGetLoading)
          .isLoading,
      };

    case ATTENDANCEDETAILSREPORT.PATCH_LOADING:
      return {
        ...state,
        action: (action as AttendanceDetailPatchLoading).type,
        patchLoading: (action as AttendanceDetailPatchLoading).isLoading,
      };

    case ATTENDANCEDETAILSREPORT.PATCH_SUCCESS:
      return {
        ...state,
        action: (action as AttendanceDetailPatchSuccess).type,
      };

    case ATTENDANCEDETAILSREPORT.PATCH_FAILED:
      return {
        ...state,
        action: (action as AttendanceDetailPatchFailed).type,
      };

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
