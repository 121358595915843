import {PROF, RESET} from '../../definitions';
import {
  ProfileInitalState,
  ProfileAction,
  ProfileGet,
  ProfileGetLoading,
  ProfileChangePassword,
} from '../../../config/interface';
import {USTATUS} from '../../../config/constant';

const initialState: ProfileInitalState = {
  isLoading: false,
  isEditing: false,
  changePasswordLoading: false,
  data: {
    id: undefined,
    name: '',
    employeeCode: '',
    isApprover: false,
    status: USTATUS.INACTIVE,
    isEmergencyMessageCreator: false,
    isEmergencyMessageApprover: false,
    isSafetyBroadcastMessageCreator: false,
    isSafetyBroadcastMessageApprover: false,
    isManagementBroadcastMessageCreator: false,
    isManagementBroadcastMessageApprover: false,
    isAttendanceReportCreator: false,
    isAttendanceReportViewer: false,
    isEmployeeAccountAdministrator: false,
    isApprovalAdministrator: false,
    isAdminUserAdministrator: false,
    isHolidayAdministrator: false,
    isDashboardMenuViewer: false,
  },
};

export default (state = initialState, action: ProfileAction) => {
  switch (action.type) {
    case PROF.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: (action as ProfileGet).data,
        },
      );

    case PROF.GET_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: (action as ProfileGetLoading).isLoading,
        },
      );

    case PROF.CHANGE_PASS_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          changePasswordLoading: (action as ProfileChangePassword).isLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
