import React from 'react';
import {PT} from '../../config/constant';
import {Navigation, NavigationItem} from '../../components/common';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ProfileData, AppState} from '../../config/interface';
import {storage} from '../../utils';
import {intlShape} from 'react-intl';
import {
  settingIcon,
  approvalIcon,
  emergencyIcon,
  masterIcon,
} from '../../config/icon';
import {Modal, Button, Icon} from '../../components/common';
import {signout} from '../../store/actions/signout';
import './_index.scss';

interface Prop extends RouteComponentProps {
  profile: ProfileData;
  signout(): void;
}

enum NavigationStep {
  DEFAULT = 'default',
  REPORT = 'report',
}

interface State {
  isCollapse: boolean;
  navigationStep: NavigationStep;
}

class NavigationBar extends React.Component<Prop, State> {
  static contextTypes = {
    intl: intlShape,
  };
  private signoutModalRef: React.RefObject<Modal>;

  constructor(props: Prop) {
    super(props);
    let navigationStep = NavigationStep.DEFAULT;

    if (
      ([PT.ATTDETAILREPORT, PT.ATTSUMMARYREPORT] as string[]).includes(
        props.history.location.pathname,
      )
    ) {
      navigationStep = NavigationStep.REPORT;
    }

    this.signoutModalRef = React.createRef<Modal>();
    this.state = {
      isCollapse: storage.getMenuCollapse()
        ? storage.getMenuCollapse() === 'true'
          ? true
          : false
        : false,
      navigationStep,
    };
  }

  render() {
    const {profile, signout, history} = this.props;
    const {isCollapse, navigationStep} = this.state;
    const {intl} = this.context;

    return (
      <>
        <Navigation
          className="navigation-bar"
          isCollapse={isCollapse}
          isSidebarOnDesktop
          fixedFirstEle={
            <div className="navigation-bar__header">
              <div className="navigation-bar__header__icon">
                <Icon data="person" />
              </div>
              <div className="navigation-bar__header__desc">
                <h6>
                  {intl.formatMessage({
                    id: 'navigationLabelSigninAs',
                    defaultMessage: 'navigationLabelSigninAs',
                  })}
                </h6>
                <h3>{profile.name} </h3>
                <p>
                  {intl.formatMessage({
                    id: 'navigationLabelEmployeeId',
                    defaultMessage: 'navigationLabelEmployeeId',
                  })}
                  {profile.employeeCode}
                </p>

                <Button
                  color="transparent-danger"
                  label="menuSignout"
                  size="small"
                  onClick={() => this.signoutModalRef.current!.openModal()}
                />
              </div>
            </div>
          }
          childrenEle={{
            [NavigationStep.DEFAULT]: (
              <>
                {profile.isDashboardMenuViewer && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.DASHBOARD}
                    icon="bar_chart"
                    label="menuDashboard"
                    withLine
                  />
                )}

                {(profile.isAttendanceReportViewer ||
                  profile.isAttendanceReportCreator) && (
                  <NavigationItem
                    className={
                      (
                        [PT.ATTDETAILREPORT, PT.ATTSUMMARYREPORT] as string[]
                      ).includes(history.location.pathname)
                        ? 'active'
                        : undefined
                    }
                    isSidebarOnDesktop
                    isParentSubMenu
                    icon={'text_snippet'}
                    label="menuReport"
                    onClick={() => {
                      this.setState({navigationStep: NavigationStep.REPORT});
                    }}
                    withLine
                  />
                )}

                {profile.isApprovalAdministrator && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.APPROVALSETTINGS}
                    icon={approvalIcon}
                    label="menuApprovalSetting"
                    withLine
                  />
                )}

                {profile.isEmployeeAccountAdministrator && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.EMPLOYEE}
                    icon={'group'}
                    label="menuEmployee"
                    withLine
                  />
                )}

                {profile.isAdminUserAdministrator && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.ADMINUSER}
                    icon={'build'}
                    label="menuAdminUser"
                    withLine
                  />
                )}

                {(profile.isManagementBroadcastMessageCreator ||
                  profile.isManagementBroadcastMessageApprover ||
                  profile.isSafetyBroadcastMessageCreator ||
                  profile.isSafetyBroadcastMessageApprover) && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.BROADCAST}
                    icon={'chat'}
                    label="menuBroadcast"
                    withLine
                  />
                )}

                {profile.isEmergencyMessageCreator && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.EMERGENCY}
                    icon={emergencyIcon}
                    label="menuEmergency"
                    withLine
                  />
                )}

                <NavigationItem
                  isSidebarOnDesktop
                  target={PT.MASTER}
                  icon={masterIcon}
                  label="menuMaster"
                  withLine
                />

                <NavigationItem
                  isSidebarOnDesktop
                  target={PT.SETTING}
                  icon={settingIcon}
                  label="menuSetting"
                  withLine
                />
              </>
            ),
            [NavigationStep.REPORT]: (
              <>
                <NavigationItem
                  isSidebarOnDesktop
                  isExit
                  isParentSubMenu
                  icon={'arrow_back'}
                  label="labelBack"
                  onClick={() => {
                    this.setState({navigationStep: NavigationStep.DEFAULT});
                  }}
                  withLine
                />

                {(profile.isAttendanceReportViewer ||
                  profile.isAttendanceReportCreator) && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.ATTDETAILREPORT}
                    icon="table_chart"
                    label="subMenuAttDetailReport"
                    withLine
                  />
                )}

                {(profile.isAttendanceReportViewer ||
                  profile.isAttendanceReportCreator) && (
                  <NavigationItem
                    isSidebarOnDesktop
                    target={PT.ATTSUMMARYREPORT}
                    icon="table_chart"
                    label="subMenuAttSummaryReport"
                    withLine
                  />
                )}
              </>
            ),
          }}
          currentChildren={navigationStep}
          fixedLastEle={
            <NavigationItem
              isSidebarOnDesktop
              isCollapseButton
              icon={isCollapse ? 'last_page' : 'first_page'}
              label="navigationCollapse"
              onClick={() =>
                this.setState({isCollapse: !isCollapse}, () =>
                  storage.setMenuCollapse(!isCollapse),
                )
              }
            />
          }
        />

        <Modal
          mode={2}
          content="contentSignout"
          title="titleConfirmSignout"
          ref={this.signoutModalRef}
          onConfirmed={() => signout()}
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  profile: state.profile.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signout,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationBar),
);
