import axios, {AxiosRequestConfig} from 'axios';
import queryString from 'query-string';
import {storage} from '../utils';
import {store} from '../store';
import {signout} from '../store/actions/signout';
import {showAlert} from '../store/actions/alert';
import env from '../config/environment';
import {
  ISigninReq,
  IEmployeeChangePassReq,
  IApprovalSettingsItemReq,
  IAdminUsersReq,
  IChangePasswordReq,
  IBroadcastCreateReq,
  IBroadcastRemarkReq,
  IRenewPasswordReq,
  IEmployeePostReq,
  IEmployeePatchReq,
  IDepartmentPostReq,
  IDepartmentPatchReq,
  IPositionPostReq,
  IPositionPatchReq,
  JobApplicantReportParam,
  AttendanceDetailReportParam,
  IEmergencyRejectParam,
  IEmergencyUnpublishParam,
  IEmergencyPostParam,
  IEmergencyPatchParam,
  IBroadcastEditeReq,
  AttendanceSummaryReportParam,
  AttendanceDetailPatchReq,
  JobPositionPostReq,
  JobPositionPatchReq,
} from '../config/interface';

const baseUrl = env.api;
const api = {
  signin: (signin: ISigninReq) => {
    return axios.post(`${baseUrl}/auth/admin/login`, signin);
  },
  renewPassword: (renewPassword: IRenewPasswordReq) => {
    return axios.patch(`${baseUrl}/auth/admin/password`, renewPassword);
  },
  getProfile: () => {
    return axios.get(`${baseUrl}/users/me`);
  },
  changePassword: (req: IChangePasswordReq) => {
    return axios.patch(`${baseUrl}/users/me`, req);
  },
  getEmployee: () => {
    return axios.get(`${baseUrl}/users`);
  },
  postEmployee: (req: IEmployeePostReq) => {
    return axios.post(`${baseUrl}/users`, req);
  },
  patchEmployee: (id: number, req: IEmployeePatchReq) => {
    return axios.patch(`${baseUrl}/users/${id}`, req);
  },
  deleteEmployee: (id: number) => {
    return axios.post(`${baseUrl}/users/${id}/deactivate`);
  },
  reactiveUser: (id: number) => {
    return axios.post(`${baseUrl}/users/${id}/activate `);
  },
  changeEmployeePassword: (id: number, req: IEmployeeChangePassReq) => {
    return axios.patch(`${baseUrl}/users/${id}`, req);
  },
  getJobPositions: () => {
    return axios.get(`${baseUrl}/job-positions`);
  },
  createJobPosition: (req: JobPositionPostReq) => {
    return axios.post(`${baseUrl}/job-positions`, req);
  },
  updateJobPosition: (id: number, req: JobPositionPatchReq) => {
    return axios.patch(`${baseUrl}/job-positions/${id}`, req);
  },
  getDepartments: () => {
    return axios.get(`${baseUrl}/departments`);
  },
  postDepartment: (req: IDepartmentPostReq) => {
    return axios.post(`${baseUrl}/departments`, req);
  },
  patchDepartment: (id: number, req: IDepartmentPatchReq) => {
    return axios.patch(`${baseUrl}/departments/${id}`, req);
  },
  getPositions: () => {
    return axios.get(`${baseUrl}/positions`);
  },
  postPosition: (req: IPositionPostReq) => {
    return axios.post(`${baseUrl}/positions`, req);
  },
  patchPosition: (id: number, req: IPositionPatchReq) => {
    return axios.patch(`${baseUrl}/positions/${id}`, req);
  },
  getApprovalSettings: () => {
    return axios.get(`${baseUrl}/approval-settings`);
  },
  postApprovalSettings: (req: IApprovalSettingsItemReq) => {
    return axios.post(`${baseUrl}/approval-settings`, req);
  },
  patchApprovalSettings: (id: number, req: IApprovalSettingsItemReq) => {
    return axios.patch(`${baseUrl}/approval-settings/${id}`, req);
  },
  deleteApprovalSettings: (id: number) => {
    return axios.delete(`${baseUrl}/approval-settings/${id}`);
  },
  getAdminUsers: () => {
    return axios.get(`${baseUrl}/admins`);
  },
  patchPriviledgeAdminUsers: (id: number, req: IAdminUsersReq) => {
    return axios.patch(`${baseUrl}/admins/${id}`, req);
  },
  getAttendanceApprovalReports: (startDate: string, endDate: string) => {
    return axios.get(
      `${baseUrl}/attendance/approval/report?start=${startDate}&end=${endDate}`,
    );
  },
  getAttendanceApprovalDetail: (date: Date) => {
    return axios.get(
      `${baseUrl}/attendance/approval/report/detail?date=${date}`,
    );
  },
  getAttendanceSubmissionReports: (startDate: string, endDate: string) => {
    return axios.get(
      `${baseUrl}/attendance/submission/report?start=${startDate}&end=${endDate}`,
    );
  },
  getAttendanceSubmissionDetail: (query: string) => {
    return axios.get(`${baseUrl}/attendance/submission?${query}`);
  },
  getBroadcasts: (query: string) => {
    return axios.get(`${baseUrl}/broadcasts?${query}`);
  },
  getBroadcastDetail: (id: number) => {
    return axios.get(`${baseUrl}/broadcasts/${id}`);
  },
  postBroadcasts: (req: IBroadcastCreateReq) => {
    return axios.post(`${baseUrl}/broadcasts`, req);
  },
  submitBroadcast: (id: number) => {
    return axios.post(`${baseUrl}/broadcasts/${id}/submit`);
  },
  deleteBroadcast: (id: number) => {
    return axios.delete(`${baseUrl}/broadcasts/${id}`);
  },
  approveBroadcast: (id: number) => {
    return axios.post(`${baseUrl}/broadcasts/${id}/approve`);
  },
  cancelBroadcast: (id: number, req: IBroadcastRemarkReq) => {
    return axios.post(`${baseUrl}/broadcasts/${id}/cancel-publish`, req);
  },
  rejectBroadcast: (id: number, req: IBroadcastRemarkReq) => {
    return axios.post(`${baseUrl}/broadcasts/${id}/reject`, req);
  },
  revisionBroadcast: (id: number) => {
    return axios.post(`${baseUrl}/broadcasts/${id}/revision/submit`);
  },
  patchBroadcast: (id: number, req: IBroadcastEditeReq) => {
    return axios.patch(`${baseUrl}/broadcasts/${id}`, req);
  },
  getEmergency: (query: string) => {
    return axios.get(`${baseUrl}/emergency-messages?${query}`);
  },
  uploadImageAttachment: (req: FormData) => {
    return axios.post(`${baseUrl}/media/image`, req);
  },
  uploadVideoAttachment: (req: FormData) => {
    return axios.post(`${baseUrl}/media/video`, req);
  },
  postEmergency: (body: IEmergencyPostParam) => {
    return axios.post(`${baseUrl}/emergency-messages`, body);
  },
  submitEmergency: (id: number) => {
    return axios.post(`${baseUrl}/emergency-messages/${id}/submit`);
  },
  getEmergencyDetail: (id: number) => {
    return axios.get(`${baseUrl}/emergency-messages/${id}`);
  },
  deleteEmergency: (id: number) => {
    return axios.delete(`${baseUrl}/emergency-messages/${id}`);
  },
  patchEmergency: (id: number, req: IEmergencyPatchParam) => {
    return axios.patch(`${baseUrl}/emergency-messages/${id}`, req);
  },
  approveEmergency: (id: number) => {
    return axios.post(`${baseUrl}/emergency-messages/${id}/approve`);
  },
  unpublishEmergency: (id: number, req: IEmergencyUnpublishParam) => {
    return axios.post(
      `${baseUrl}/emergency-messages/${id}/cancel-publish`,
      req,
    );
  },
  rejectEmergency: (id: number, req: IEmergencyRejectParam) => {
    return axios.post(`${baseUrl}/emergency-messages/${id}/reject`, req);
  },
  submitRevisionEmergency: (id: number) => {
    return axios.post(`${baseUrl}/emergency-messages/${id}/revision/submit`);
  },
  getJobPost: () => {
    return axios.get(`${baseUrl}/postings`);
  },
  getJobApplicantReport: (
    jobPostingId: string,
    param: JobApplicantReportParam,
  ) => {
    return axios.get(
      `${baseUrl}/applications/postings/${jobPostingId}/exam-report?${queryString.stringify(
        param,
      )}`,
    );
  },
  getJobApplicantReportExport: (
    jobPostingId: string,
    param: JobApplicantReportParam,
  ) => {
    return axios.get(
      `${baseUrl}/applications/postings/${jobPostingId}/exam-report/export?${queryString.stringify(
        param,
      )}`,
      {
        responseType: 'arraybuffer',
      },
    );
  },
  getAttendanceDetailReport: (param: AttendanceDetailReportParam) => {
    return axios.get(
      `${baseUrl}/attendance/details?${queryString.stringify(param)}`,
    );
  },
  getAttendanceDetailReportExport: (param: AttendanceDetailReportParam) => {
    return axios.get(
      `${baseUrl}/attendance/details/export?${queryString.stringify(param)}`,
      {
        responseType: 'arraybuffer',
      },
    );
  },
  patchAttendanceDetail: (id: number, req: AttendanceDetailPatchReq) => {
    return axios.patch(`${baseUrl}/attendance/${id}`, req);
  },
  getAttendanceSummaryReport: (param: AttendanceSummaryReportParam) => {
    return axios.get(
      `${baseUrl}/attendance/summary?${queryString.stringify(param)}`,
    );
  },
  getAttendanceSummaryReportExport: (param: AttendanceSummaryReportParam) => {
    return axios.get(
      `${baseUrl}/attendance/summary/export?${queryString.stringify(param)}`,
      {
        responseType: 'arraybuffer',
      },
    );
  },
};

axios.interceptors.request.use(
  (config) => {
    const auth = storage.getToken();
    if (auth) {
      config.headers.authorization = auth;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a 401 response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== '/signin'
    ) {
      if (error.response.data && error.response.data.error_code === 4003) {
        store.dispatch<any>(
          showAlert({
            type: 'warning',
            message: '4003',
          }),
        );
      }

      store.dispatch<any>(signout());
    }

    return Promise.reject(error);
  },
);

export {api as default, baseUrl};
