import {EMPLOYEE, RESET} from '../../definitions';

const initialState = {
  data: [],
  isLoading: false,
  formIsLoading: false,
  postEmployeeLoading: false,
  patchEmployeeLoading: false,
  employeeSetToPatch: undefined,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case EMPLOYEE.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
        },
      );

    case EMPLOYEE.GET_FORM_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          formIsLoading: action.formIsLoading,
        },
      );

    case EMPLOYEE.POST_EMPLOYEE_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          postEmployeeLoading: action.postEmployeeLoading,
        },
      );

    case EMPLOYEE.SET_EMPLOYEE_PATCH:
      return Object.assign(
        {},
        {
          ...state,
          employeeSetToPatch: action.data,
        },
      );

    case EMPLOYEE.PATCH_EMPLOYEE_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          patchEmployeeLoading: action.patchEmployeeLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
