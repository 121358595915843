import logger from '../logger';
import api from '../../api';

export const postImagesReturnStr = (images: Array<any>) => {
  return new Promise(function (resolve, reject) {
    Promise.all(
      images.map((element) => {
        // console.log('postImagesReturnStr element ', element)
        var formData = new FormData();
        formData.append('image', element);
        return api.uploadImageAttachment(formData);
      }),
    )
      .then((res) => {
        resolve(res.map((item) => item.data.location));
      })
      .catch((err) => {
        logger(
          err,
          'error',
          'postImagesReturnStr',
          false,
          err && err.response && err.response.data,
          {arr_req: images},
        );
        reject([]);
      });
  });
};

export const postVideos = (images: Array<any>) => {
  return new Promise(function (resolve, reject) {
    Promise.all(
      images.map((element) => {
        var formData = new FormData();
        formData.append('video', element);
        return api.uploadVideoAttachment(formData);
      }),
    )
      .then((res) => {
        resolve(res.map((item) => item.data.location));
      })
      .catch((err) => {
        logger(
          err,
          'error',
          'postVideos',
          false,
          err && err.response && err.response.data,
          {arr_req: images},
        );
        reject([]);
      });
  });
};
