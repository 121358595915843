import {RESET, APPROVALSETTINGS} from '../../definitions';

const initialState = {
  data: [],
  isLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case APPROVALSETTINGS.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
