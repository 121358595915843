import {RESET, ATTENDANCESUMMARYREPORT} from '../../definitions';
import {settings} from '../../../config/config';
import {cookie} from '../../../utils';
import {
  AttendanceSummaryReportAction,
  AttendanceSummaryReportGet,
  AttendanceSummaryReportGetLoading,
  AttendanceSummaryReportFilter,
  AttendanceSummaryReportExportGetLoading,
} from '../../../config/interface';

const getSetting = cookie.getAttDetFilter();
const initialSetting = getSetting
  ? JSON.parse(getSetting)
  : settings.filterAttendanceSummaryReport;

const initialState = {
  data: [],
  isLoading: false,
  filter: {
    year: initialSetting.year,
    month: initialSetting.month,
  },
  exportIsLoading: false,
};

export default (
  state = initialState,
  action: AttendanceSummaryReportAction,
) => {
  switch (action.type) {
    case ATTENDANCESUMMARYREPORT.GET:
      return {
        ...state,
        data: (action as AttendanceSummaryReportGet).data,
      };

    case ATTENDANCESUMMARYREPORT.GET_LOADING:
      return {
        ...state,
        isLoading: (action as AttendanceSummaryReportGetLoading).isLoading,
      };

    case ATTENDANCESUMMARYREPORT.FILTER:
      return {
        ...state,
        filter: (action as AttendanceSummaryReportFilter).data,
      };

    case ATTENDANCESUMMARYREPORT.GET_EXPORT_LOADING:
      return {
        ...state,
        exportIsLoading: (action as AttendanceSummaryReportExportGetLoading)
          .isLoading,
      };

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
