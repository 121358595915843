// Name for localstorage
export enum LS {
  TOKEN = 'NBA-ADMIN-TOKEN',
  LANG = 'NBA-ADMIN-LANG',
  MENU = 'NBA-ADMIN-MENU',
  PROFILE = 'NBA-ADMIN-PROFILE',
  EMPLOYEE = 'NBA-ADMIN-EMPLOYEE',
  BROADCAST = 'NBA-ADMIN-BROADCAST',
  MASTER = 'NBA-ADMIN-MASTER',
  APPROVALSETTINGS = 'NBA-ADMIN-APPROVALSETTINGS',
  ADMINUSER = 'NBA-ADMIN-ADMINUSER',
  ATTENDANCEAPPROVALREPORTS = 'NBA-ADMIN-ATTENDANCEAPPROVALREPORTS',
  ATTENDANCESUBMISSIONREPORTS = 'NBA-ADMIN-ATTENDANCESUBMISSIONREPORTS',
  JOBPOST = 'NBA-ADMIN-JOBPOST',
  JOBAPPLICANTREPORT = 'NBA-ADMIN-JOBAPPLICANTREPORT',
  ATTENDANCEDETAILREPORT = 'NBA-ADMIN-ATTENDANCEDETAILREPORT',
  ATTENDANCESUMMARYREPORT = 'NBA-ADMIN-ATTENDANCESUMMARYREPORT',
}

// Name for cookie
export enum CK {
  APPFIL = 'NBA-ADMIN-APPROVAL-FILTER',
  SUBFIL = 'NBA-ADMIN-SUBMISSION-FILTER',
  BROFIL = 'NBA-ADMIN-BROADCAST-FILTER',
  EMERFIL = 'NBA-ADMIN-EMERGENCY-FILTER',
  ETTDETRFIL = 'NBA-ADMIN-ATTENDANCE-DETAIL-FILTER',
  ETTSUMRFIL = 'NBA-ADMIN-ATTENDANCE-SUMMARY-FILTER',
}

// Name for Path
export enum PT {
  SIGNIN = '/signin',
  SETTING = '/setting',
  APPROVALSETTINGS = '/approvalsettings',
  ADMINUSER = '/adminuser',
  EMPLOYEE = '/employee',
  EMPLOYEEADD = '/employee/form',
  EMPLOYEEEDIT = '/employee/edit',
  DASHBOARD = '/dashboard',
  ATTDETAILREPORT = '/report/attendance/detail',
  ATTSUMMARYREPORT = '/report/attendance/summary',
  EMERGENCY = '/emergency',
  EMERGENCYADD = '/emergency/add',
  EMERGENCYDETAIL = '/emergency/:emergencyId',
  EMERGENCYEDIT = '/emergency/edit/:emergencyId',
  BROADCAST = '/broadcast',
  BROADCASTDETAIL = '/broadcast/:broadcastId',
  BROADCASTADD = '/broadcast/form',
  BROADCASTEDIT = '/broadcast/form/:broadcastId',
  MASTER = '/master',
}

// language
export enum LANG {
  EN = 'en',
  ID = 'id',
}

export enum USTATUS {
  NEW = 101,
  ACTIVE = 201,
  INACTIVE = 202,
}

// broadcast status
export enum BROADCASTSTATUS {
  DRAFT = 101,
  DELETED = 102,
  SUBMITTED = 201,
  ON_REVISION = 202,
  APPROVED = 301,
  PUBLISHED = 401,
  EXPIRED = 501,
}

export enum ATTENDANCESTATUS {
  DRAFT = 101,
  DELETED = 102,
  SUBMITTED = 201,
  ON_REVISION = 202,
  APPROVED = 301,
}

export enum BROADCASTTYPE {
  MANAGEMENT = 1,
  SAFETY = 2,
}

// broadcast order by
export enum BROADCASTCONTENTTYPE {
  ARTICLE = 'article',
  IMAGE = 'image',
  VIDEO = 'video',
}

// broadcast order by
export enum BROADCASTOB {}

export enum EMERGENCYSTATUS {
  DRAFT = 101,
  DELETED = 102,
  SUBMITTED = 201,
  ON_REVISION = 202,
  PUBLISHED = 301,
  EXPIRED = 401,
}

// submit type
export enum SUBMITTYPE {
  Submit = 'submit',
  Save = 'save',
}

// use only on attendanceSubmissionReports view
export enum SUBMSTATUS {
  NOTSUBMITTED = 0,
  SUBMITTED = 1,
  ALL = 2,
}

export enum GETDATADIR {
  ASC = 'asc',
  DESC = 'desc',
}

// Leave reason
export enum ATYPE {
  SICK = 'SAKIT',
  PERMIT = 'IZIN',
}

export enum PRIVILEDGE {
  EMERGENCY_MESSAGE_CREATOR = 1,
  EMERGENCY_MESSAGE_APPROVER = 2,
  SAFETY_BROADCAST_MESSAGE_CREATOR = 3,
  SAFETY_BROADCAST_MESSAGE_APPROVER = 4,
  MANAGEMENT_BROADCAST_MESSAGE_CREATOR = 5,
  MANAGEMENT_BROADCAST_MESSAGE_APPROVER = 6,
  ATTENDANCE_REPORT_VIEWER = 7,
  ATTENDANCE_REPORT_CREATOR = 13,
  EMPLOYEE_ACCOUNT_ADMINISTRATOR = 8,
  APPROVAL_ADMINISTRATOR = 9,
  ADMIN_USER_ADMINISTRATOR = 10,
  HOLIDAY_ADMINISTRATOR = 11,
  DASHBOARD_MENU_VIEWER = 12,
}

export function googleMapSearch(lat: string, lng: string) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
}

export const dateFormat = 'MMM DD, YYYY HH:mm';
export const dateFormatCorrect = 'YYYY-MM-DD HH:mm';
