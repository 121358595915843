export const CONF = {
  LANG: '@@config/LANG',
  LANG_CHANGING: '@@config/LANG_CHANGING',
};

export const ALERT = {
  SHOW: '@@alert/SHOW',
  HIDE: '@@alert/HIDE',
};

export const RESET = {
  ALL: '@@reset/RESET_ALL',
};

export const SIGNIN = {
  LOADING: '@@signin/LOADING',
  RENEW_PASS_LOADING: '@@signin/RENEW_PASS_LOADING',
};

export const BROADCAST = {
  LOADING: '@@broadcast/LOADING',
  GET_DETAIL: '@@broadcast/GET_DETAIL',
  GET_DETAIL_LOADING: '@@broadcast/GET_DETAIL_LOADING',
  FILTER: '@@broadcast/FILTER',
  FILTER_LOADING: '@@broadcast/FILTER_LOADING',
  SUBMIT_LOADING: '@@broadcast/SUBMIT_LOADING',
};

export const PROF = {
  GET: '@@profile/GET',
  GET_LOADING: '@@profile/GET_LOADING',
  CHANGE_PASS_LOADING: '@@profile/CHANGE_PASS_LOADING',
};

export const EMPLOYEE = {
  GET: '@@employee/GET',
  GET_LOADING: '@@employee/GET_LOADING',
  GET_FORM_LOADING: '@@employee/GET_FORM_LOADING',
  POST_EMPLOYEE_LOADING: '@@employee/POST_EMPLOYEE_LOADING',
  SET_EMPLOYEE_PATCH: '@@employee/SET_EMPLOYEE_PATCH',
  PATCH_EMPLOYEE_LOADING: '@@employee/PATCH_EMPLOYEE_LOADING',
};

export const ATTENDANCEAPPROVALREPORTS = {
  GET: '@@attendanceapprovalreports/GET',
  GET_LOADING: '@@attendanceapprovalreports/GET_LOADING',
  GET_DETAIL: '@@attendanceapprovalreports/GET_DETAIL',
  GET_DETAIL_LOADING: '@@attendanceapprovalreports/GET_DETAIL_LOADING',
  FILTER: '@@attendanceapprovalreports/FILTER',
  FILTER_LOADING: '@@attendanceapprovalreports/FILTER_LOADING',
};

export const ATTENDANCESUBMISSIONREPORTS = {
  FILTER: '@@attendancesubmissonreports/FILTER',
  FILTER_LOADING: '@@attendancesubmissonreports/FILTER_LOADING',
  GET: '@@attendancesubmissonreports/GET',
  GET_LOADING: '@@attendancesubmissonreports/GET_LOADING',
  GET_DETAIL: '@@attendancesubmissonreports/GET_DETAIL',
  GET_DETAIL_LOADING: '@@attendancesubmissonreports/GET_DETAIL_LOADING',
  FILTER_DETAIL: '@@attendancesubmissonreports/FILTER_DETAIL',
  FILTER_DETAIL_LOADING: '@@attendancesubmissonreports/FILTER_DETAIL_LOADING',
};

export const APPROVALSETTINGS = {
  GET: '@@approvalsettings/GET',
  GET_LOADING: '@@approvalsettings/GET_LOADING',
};

export const ADMINUSERS = {
  GET: '@@adminusers/GET',
  GET_LOADING: '@@adminusers/GET_LOADING',
  GET_FORM_LOADING: '@@adminusers/GET_FORM_LOADING',
};

export const DEPARTMENT = {
  POST_DEPARTMENT_LOADING: '@@department/POST_DEPARTMENT_LOADING',
  PATCH_DEPARTMENT_LOADING: '@@department/PATCH_DEPARTMENT_LOADING',
};

export const POSITION = {
  POST_POSITION_LOADING: '@@position/POST_POSITION_LOADING',
  PATCH_POSITION_LOADING: '@@position/PATCH_POSITION_LOADING',
};

export const JOB_POSITION_CREATE_FETCH = 'JOB_POSITION_CREATE_FETCH';
export const JOB_POSITION_CREATE_SUCCESS = 'JOB_POSITION_CREATE_SUCCESS';
export const JOB_POSITION_CREATE_FAILED = 'JOB_POSITION_CREATE_FAILED';
export const JOB_POSITION_UPDATE_FETCH = 'JOB_POSITION_UPDATE_FETCH';
export const JOB_POSITION_UPDATE_SUCCESS = 'JOB_POSITION_UPDATE_SUCCESS';
export const JOB_POSITION_UPDATE_FAILED = 'JOB_POSITION_UPDATE_FAILED';

export const MASTER = {
  GET: '@@master/GET',
  GET_LOADING: '@@master/GET_LOADING',
};

export const EMERGENCY = {
  GET: '@@emergency/GET',
  LOADING: '@@emergency/LOADING',
  GET_LOADING: '@@emergency/GET_LOADING',
  SUBMIT: '@@emergency/SUBMIT',
  SUBMIT_LOADING: '@@emergency/SUBMIT_LOADING',
  SAVE: '@@emergency/SAVE',
  DETAIL: '@@emergency/DETAIL',
  SAVE_LOADING: '@@emergency/SAVE_LOADING',
  FILTER: '@@emergency/FILTER',
  FILTER_LOADING: '@@emergency/FILTER_LOADING',
  APPROVE: '@@emergency/APPROVE',
  APPROVE_LOADING: '@@emergency/APPROVE_LOADING',
  REVISION: '@@emergency/REVISION',
  REVISION_LOADING: '@@emergency/REVISION_LOADING',
  REJECT: '@@emergency/REJECT',
  REJECT_LOADING: '@@emergency/REJECT_LOADING',
  CANCEL: '@@emergency/CANCEL',
  CANCEL_LOADING: '@@emergency/CANCEL_LOADING',
};

export const JOBPOST = {
  GET: '@@jobpost/GET',
  GET_LOADING: '@@jobpost/GET_LOADING',
};

export const JOBAPPLICANT = {
  GET: '@@jobapplicant/GET',
  FILTER: '@@jobapplicant/FILTER',
  GET_LOADING: '@@jobapplicant/GET_LOADING',
  GET_EXPORT_LOADING: '@@jobapplicant/GET_EXPORT_LOADING',
};

export const ATTENDANCEDETAILSREPORT = {
  GET: '@@attendancedetailreport/GET',
  GET_LOADING: '@@attendancedetailreport/GET_LOADING',
  FILTER: '@@attendancedetailreport/FILTER',
  GET_EXPORT_LOADING: '@@attendancedetailreport/GET_EXPORT_LOADING',
  PATCH_LOADING: '@@attendancedetailreport/PATCH_LOADING',
  PATCH_SUCCESS: '@@attendancedetailreport/PATCH_SUCCESS',
  PATCH_FAILED: '@@attendancedetailreport/PATCH_FAILED',
};

export const ATTENDANCESUMMARYREPORT = {
  GET: '@@attendancesummaryreport/GET',
  GET_LOADING: '@@attendancesummaryreport/GET_LOADING',
  FILTER: '@@attendancesummaryreport/FILTER',
  GET_EXPORT_LOADING: '@@attendancesummaryreport/GET_EXPORT_LOADING',
};
