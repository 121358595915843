import React from 'react';
import classnames from 'classnames';
import {intlShape} from 'react-intl';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Button} from '../../components/common';
import {hideAlert} from '../../store/actions/alert';
import {AppState, AlertInitialState} from '../../config/interface';
import './_index.scss';

interface Prop {
  className?: string;
  isSignedIn: boolean;
  hideAlert(): void;
  alert: AlertInitialState;
}

class Alert extends React.Component<Prop> {
  static contextTypes = {
    intl: intlShape,
  };

  render() {
    const {className, isSignedIn, alert, hideAlert} = this.props;

    const {intl} = this.context;

    let startAutoClose;

    if (alert.isShowing) {
      clearTimeout(startAutoClose);
      startAutoClose = setTimeout(() => {
        hideAlert();
      }, 12000);
    } else {
      clearTimeout(startAutoClose);
    }

    const classes = classnames(
      'alert',
      `alert--${alert.type}`,
      {'alert--signed': isSignedIn},
      {'alert--showed': alert.isShowing},
      className,
    );

    return (
      <>
        <div className={classes}>
          <div className="alert__content">
            {alert.message &&
              intl.formatMessage(
                {id: alert.message, defaultMessage: alert.message},
                alert.values,
              )}
          </div>
          <Button type="inline" icon="close" onClick={() => hideAlert()} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  alert: state.alert,
  isSignedIn: !!state.profile.data.id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideAlert,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
