import Cookies from 'js-cookie';
import {CK} from '../../config/constant';

// ------------------------------- //
// Get approval App filter
export const getAppFilter = () => Cookies.get(CK.APPFIL);

// Set approval App filter
export const setAppFilter = (filter: any) =>
  Cookies.set(CK.APPFIL, JSON.stringify(filter));

// Remove approval App filter
export const removeAppFilter = () => Cookies.remove(CK.APPFIL);

// ------------------------------- //
// Get approval Subm filter
export const getSubmFilter = () => Cookies.get(CK.SUBFIL);

// Set approval Subm filter
export const setSubmFilter = (filter: any) =>
  Cookies.set(CK.SUBFIL, JSON.stringify(filter));

// Remove approval Subm filter
export const removeSubmFilter = () => Cookies.remove(CK.SUBFIL);

// ------------------------------- //
// Get broadcast filter
export const getBroadcastFilter = () => Cookies.get(CK.BROFIL);

// Set broadcast filter
export const setBroadcastFilter = (filter: any) =>
  Cookies.set(CK.BROFIL, JSON.stringify(filter));

// Remove broadcast filter
export const removeBroadcastFilter = () => Cookies.remove(CK.BROFIL);

// ------------------------------- //
// Get approval AttDet filter
export const getAttDetFilter = () => Cookies.get(CK.ETTDETRFIL);

// Set approval AttDet filter
export const setAttDetFilter = (filter: any) =>
  Cookies.set(CK.ETTDETRFIL, JSON.stringify(filter));

// Remove approval AttDet filter
export const removeAttDetFilter = () => Cookies.remove(CK.ETTDETRFIL);

// ------------------------------- //
// Get approval AttSum filter
export const getAttSumFilter = () => Cookies.get(CK.ETTSUMRFIL);

// Set approval AttSum filter
export const setAttSumFilter = (filter: any) =>
  Cookies.set(CK.ETTSUMRFIL, JSON.stringify(filter));

// Remove approval AttSum filter
export const removeAttSumFilter = () => Cookies.remove(CK.ETTSUMRFIL);
