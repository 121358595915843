import {SIGNIN, RESET} from '../../definitions';
import {
  SigninInitialState,
  SigninLoading,
  SigninRenewPassword,
  SigninAction,
} from '../../../config/interface';

const initialState: SigninInitialState = {
  isLoading: false,
  renewPassLoading: false,
};

export default (state = initialState, action: SigninAction) => {
  switch (action.type) {
    case SIGNIN.LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: (action as SigninLoading).isLoading,
        },
      );

    case SIGNIN.RENEW_PASS_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          renewPassLoading: (action as SigninRenewPassword).isLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
