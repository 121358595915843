// Import constant for local storage
import {LS, LANG} from '../../config/constant';
import {
  ProfileData,
  IEmployee,
  Master,
  IApprovalSettings,
  IAdminUsers,
  IAttendApprovalReports,
  IAttendSubmissionReports,
  IBroadcast,
  AttendanceSummaryReportDataRes,
  JobPostItem,
  JobApplicantReportDataRes,
  AttendanceDetailReportDataRes,
} from '../../config/interface';

// ------------------------------- //
// Get attendance Summary report
export const getAttendSummaryReport = () =>
  localStorage.getItem(LS.ATTENDANCESUMMARYREPORT);
// Set attendance Summary report
export const setAttendSummaryReport = (
  data: AttendanceSummaryReportDataRes[],
) => localStorage.setItem(LS.ATTENDANCESUMMARYREPORT, JSON.stringify(data));
// Remove attendance Summary report
export const removeAttendSummaryReport = () =>
  localStorage.removeItem(LS.ATTENDANCESUMMARYREPORT);

// ------------------------------- //
// Get attendance Detail reports
export const getAttendDetailReport = () =>
  localStorage.getItem(LS.ATTENDANCEDETAILREPORT);
// Set attendance Detail reports
export const setAttendDetailReport = (data: AttendanceDetailReportDataRes[]) =>
  localStorage.setItem(LS.ATTENDANCEDETAILREPORT, JSON.stringify(data));
// Remove attendance Detail reports
export const removeAttendDetailReport = () =>
  localStorage.removeItem(LS.ATTENDANCEDETAILREPORT);

// ------------------------------- //
// Get broadcast
export const getBroadcast = () => localStorage.getItem(LS.BROADCAST);
// Set broadcast
export const setBroadcast = (data: IBroadcast) =>
  localStorage.setItem(LS.BROADCAST, JSON.stringify(data));
// Remove broadcast
export const removeBroadcast = () => localStorage.removeItem(LS.BROADCAST);

// Get adminUsers
export const getAdminUsers = () => localStorage.getItem(LS.ADMINUSER);
// Set adminUsers
export const setAdminUsers = (data: IAdminUsers) =>
  localStorage.setItem(LS.ADMINUSER, JSON.stringify(data));
// Remove adminUsers
export const removeAdminUsers = () => localStorage.removeItem(LS.ADMINUSER);

// ------------------------------- //
// Get attendance submission reports
export const getAttendSubmissionReports = () =>
  localStorage.getItem(LS.ATTENDANCESUBMISSIONREPORTS);
// Set attendance approval reports
export const setAttendSubmissionReports = (data: IAttendSubmissionReports) =>
  localStorage.setItem(LS.ATTENDANCESUBMISSIONREPORTS, JSON.stringify(data));
// Remove attendance approval reports
export const removeAttendSubmissionReports = () =>
  localStorage.removeItem(LS.ATTENDANCESUBMISSIONREPORTS);

// ------------------------------- //
// Get attendance approval reports
export const getAttendApprovalReports = () =>
  localStorage.getItem(LS.ATTENDANCEAPPROVALREPORTS);
// Set attendance approval reports
export const setAttendApprovalReports = (data: IAttendApprovalReports) =>
  localStorage.setItem(LS.ATTENDANCEAPPROVALREPORTS, JSON.stringify(data));
// Remove attendance approval reports
export const removeAttendApprovalReports = () =>
  localStorage.removeItem(LS.ATTENDANCEAPPROVALREPORTS);

// ------------------------------- //
// Get APPROVALSETTING
export const getApprovalSettings = () =>
  localStorage.getItem(LS.APPROVALSETTINGS);
// Set APPROVALSETTING
export const setApprovalSettings = (data: IApprovalSettings) =>
  localStorage.setItem(LS.APPROVALSETTINGS, JSON.stringify(data));
// Remove APPROVALSETTING
export const removeApprovalSettings = () =>
  localStorage.removeItem(LS.APPROVALSETTINGS);

// ------------------------------- //
// Return boolean if there is token
export const isTokenExist = () => !!localStorage.getItem(LS.TOKEN);
// Set token to local storage
export const setToken = (token: string) =>
  localStorage.setItem(LS.TOKEN, token);
// Get token
export const getToken = () => localStorage.getItem(LS.TOKEN);
// Remove token
export const removeToken = () => localStorage.removeItem(LS.TOKEN);

// ------------------------------- //
// Get lang
export const getLang = (): LANG => {
  const localStorageLang = localStorage.getItem(LS.LANG);
  if (typeof localStorageLang === 'string') {
    return localStorageLang as LANG;
  } else {
    return LANG.EN;
  }
};
// Set lang to local storage
export const setLang = (lang: LANG) => localStorage.setItem(LS.LANG, lang);

// ------------------------------- //
// Get menu collapse option
export const getMenuCollapse = () => localStorage.getItem(LS.MENU);
// Set lang to local storage
export const setMenuCollapse = (isCollapse: boolean) =>
  localStorage.setItem(LS.MENU, isCollapse ? 'true' : 'false');

// ------------------------------- //
// Get profile
export const getProfile = () => localStorage.getItem(LS.PROFILE);
// Set profile
export const setProfile = (data: ProfileData) =>
  localStorage.setItem(LS.PROFILE, JSON.stringify(data));
// Remove profile
export const removeProfile = () => localStorage.removeItem(LS.PROFILE);

// ------------------------------- //
// Get employee
export const getEmployee = () => localStorage.getItem(LS.EMPLOYEE);
// Set employee
export const setEmployee = (data: IEmployee) =>
  localStorage.setItem(LS.EMPLOYEE, JSON.stringify(data));
// Remove employee
export const removeEmployee = () => localStorage.removeItem(LS.EMPLOYEE);

// ------------------------------- //
// Get master
export const getMaster = () => localStorage.getItem(LS.MASTER);
// Set master
export const setMaster = (data: Master) =>
  localStorage.setItem(LS.MASTER, JSON.stringify(data));
// Remove master
export const removeMaster = () => localStorage.removeItem(LS.MASTER);

//#region job Post
export const getJobPost = () => localStorage.getItem(LS.JOBPOST);
// Set JobPost
export const setJobPost = (data: JobPostItem[]) =>
  localStorage.setItem(LS.JOBPOST, JSON.stringify(data));
// Remove JobPost
export const removeJobPost = () => localStorage.removeItem(LS.JOBPOST);
//#endregion
