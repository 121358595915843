const alert = (httpCode: any, context: string, code?: number) => {
  if (code) {
    return {
      message: code,
      type: 'warning',
    };
  } else {
    if (httpCode === 200) {
      return {
        message: `alert.${httpCode}.${context}`,
        type: 'success',
      };
    } else if (httpCode === 400 || httpCode === 401) {
      return {
        message: `alert.${httpCode}.${context}`,
        type: 'warning',
      };
    } else if (httpCode === 0) {
      return {
        message: `alert.${httpCode}`,
        type: 'error',
      };
    } else {
      return {
        message: `alert.${httpCode}.${context}`,
        type: 'error',
      };
    }
  }
};

export default alert;
