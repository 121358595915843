// Import redux
import {combineReducers} from 'redux';

// Import router and redux form
import {connectRouter} from 'connected-react-router';
import {reducer as formReducer} from 'redux-form';
import config from './config';
import alert from './alert';
import signin from './signin';
import profile from './profile';
import employee from './employee';
import master from './master';
import department from './department';
import approvalSettings from './approvalSettings';
import adminUsers from './adminUsers';
import attendanceApprovalReports from './attendanceApprovalReports';
import attendanceSubmissionReports from './attendanceSubmissionReports';
import broadcast from './broadcast';
import emergency from './emergency';
import jobPost from './jobPost';
import position from './position';
import jobApplicantReport from './jobApplicantReport';
import attendanceDetailReport from './attendanceDetailReport';
import attendanceSummaryReport from './attendanceSummaryReport';
import jobPosition from './jobPosition';

/**
 * Combine all reducers
 * @param {*} history
 * history to track router change to state
 */
const reducers = (history: any) =>
  combineReducers<{}>({
    router: connectRouter(history),
    form: formReducer,
    alert,
    master,
    department,
    config,
    signin,
    profile,
    employee,
    approvalSettings,
    adminUsers,
    attendanceApprovalReports,
    attendanceSubmissionReports,
    broadcast,
    emergency,
    jobPost,
    position,
    jobApplicantReport,
    attendanceDetailReport,
    attendanceSummaryReport,
    jobPosition,
  });

export default reducers;
