import {LANG} from './constant';
import moment from 'moment';
import {ATTENDANCESTATUS} from './constant';

export const settings = {
  lang: LANG.EN,
  filterReport: {
    start: moment().startOf('day').subtract(1, 'w').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
  },
  filterDiffOneMonth: {
    start: moment().startOf('day').subtract(1, 'months').format('YYYY-MM-DD'),
    end: moment().endOf('day').format('YYYY-MM-DD'),
  },
  filterJobApplicantReport: {
    year: moment().year().toString(),
    jobPostId: undefined,
    question: '',
    answer: '',
  },
  filterAttendanceDetailReport: {
    year: moment().year().toString(),
    month: moment().month().toString(),
    employeeCode: undefined,
    status: ATTENDANCESTATUS.APPROVED,
  },
  filterAttendanceSummaryReport: {
    year: moment().year().toString(),
    month: moment().month().toString(),
  },
};
