import {ADMINUSERS, RESET} from '../../definitions';

const initialState = {
  data: [],
  isLoading: false,
  formIsLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADMINUSERS.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
        },
      );

    case ADMINUSERS.GET_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: action.isLoading,
        },
      );

    case ADMINUSERS.GET_FORM_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          formIsLoading: action.formIsLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
