import {RESET, JOBAPPLICANT} from '../../definitions';
import {settings} from '../../../config/config';
import {
  JobApplicantReportAction,
  JobApplicantReportGet,
  JobApplicantReportGetLoading,
  JobApplicantReportExportGetLoading,
  JobApplicantReportInitialState,
  JobApplicantReportFilter,
} from '../../../config/interface';

const initialSetting = settings.filterJobApplicantReport;

const initialState: JobApplicantReportInitialState = {
  data: [],
  isLoading: false,
  exportIsLoading: false,
  filter: {
    year: initialSetting.year,
    jobPostId: initialSetting.jobPostId,
    question: initialSetting.question,
    answer: initialSetting.answer,
  },
};

export default (state = initialState, action: JobApplicantReportAction) => {
  switch (action.type) {
    case JOBAPPLICANT.GET:
      return {
        ...state,
        data: (action as JobApplicantReportGet).data,
      };

    case JOBAPPLICANT.GET_LOADING:
      return {
        ...state,
        isLoading: (action as JobApplicantReportGetLoading).isLoading,
      };

    case JOBAPPLICANT.FILTER:
      return {
        ...state,
        filter: (action as JobApplicantReportFilter).data,
      };

    case JOBAPPLICANT.GET_EXPORT_LOADING:
      return {
        ...state,
        exportIsLoading: (action as JobApplicantReportExportGetLoading)
          .isLoading,
      };

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
