import {DEPARTMENT, RESET} from '../../definitions';

const initialState = {
  postDepartmentLoading: false,
  patchDepartmentLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case DEPARTMENT.PATCH_DEPARTMENT_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          patchDepartmentLoading: action.isLoading,
        },
      );

    case DEPARTMENT.POST_DEPARTMENT_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          postDepartmentLoading: action.isLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
