import {MASTER, RESET} from '../../definitions';
import {MasterInitialState} from '../../../config/interface';

const initialState: MasterInitialState = {
  isLoading: false,
  data: {
    jobPstAll: [],
    departments: [],
    positions: [],
    jobPstPerDprt: [],
    jobPst: [],
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case MASTER.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
        },
      );

    case MASTER.GET_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: action.isLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
