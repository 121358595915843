import React, {ReactElement} from 'react';
import classnames from 'classnames';
import './_index.scss';

interface ChildrenEleMap {
  [name: string]: ReactElement;
}

interface Prop {
  fixedFirstEle?: ReactElement;
  fixedLastEle?: ReactElement;
  childrenEle?: ChildrenEleMap;
  currentChildren?: string;
  className?: string;
  isCollapse?: boolean;
  isSidebarOnDesktop: boolean;
}

export class Navigation extends React.Component<Prop> {
  render() {
    const {
      children,
      isSidebarOnDesktop,
      className,
      isCollapse,
      fixedFirstEle,
      fixedLastEle,
      childrenEle,
      currentChildren,
    } = this.props;
    const classes = classnames(
      'navigation',
      {'navigation--sidebar': isSidebarOnDesktop},
      {'navigation--is-collapse': isCollapse},
      className,
    );

    return (
      <div className={classes}>
        {fixedFirstEle}
        {typeof childrenEle === 'undefined' ||
        typeof currentChildren === 'undefined'
          ? children
          : childrenEle[currentChildren]}
        {fixedLastEle}
      </div>
    );
  }
}

export default Navigation;
