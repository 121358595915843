import React, {ReactElement} from 'react';
import {NavLink} from 'react-router-dom';
import {intlShape} from 'react-intl';
import classnames from 'classnames';
import Icon from '../Icon';
import './_index.scss';

interface IProp {
  target?: string;
  icon: string | ReactElement;
  label: string;
  className?: string;
  counter?: number;
  showCounter?: boolean;
  withLine?: boolean;
  isSidebarOnDesktop?: boolean;
  isCollapseButton?: boolean;
  isParentSubMenu?: boolean;
  onClick?(): void;
  isExit?: boolean;
}

class NavigationItem extends React.Component<IProp> {
  static contextTypes = {
    intl: intlShape,
  };

  render() {
    const {
      target,
      icon,
      label,
      className,
      counter,
      showCounter,
      withLine,
      isSidebarOnDesktop,
      isCollapseButton,
      isParentSubMenu,
      isExit,
      ...others
    } = this.props;

    const {intl} = this.context;

    const classes = classnames(
      'navigation-item',
      {'navigation-item--with-line': withLine},
      {'navigation-item--sidebar': isSidebarOnDesktop},
      {'navigation-item--is-collapse-button': isCollapseButton},
      {'navigation-item--exit': isExit},
      className,
    );

    return isCollapseButton ? (
      <div className={classes} {...others}>
        <div className="navigation-item__icon">
          <Icon data={icon} />
        </div>

        <div className="navigation-item__label">
          <span>{intl.formatMessage({id: label, defaultMessage: label})}</span>
        </div>

        {showCounter && (
          <span className="navigation-item__counter">{counter}</span>
        )}
      </div>
    ) : isParentSubMenu ? (
      <div className={classes} {...others}>
        <div className="navigation-item__icon">
          <Icon data={icon} />
        </div>

        <div className="navigation-item__label">
          <span>{intl.formatMessage({id: label, defaultMessage: label})}</span>
        </div>
      </div>
    ) : (
      <NavLink
        to={target || '/'}
        className={classes}
        activeClassName="active"
        {...others}
      >
        <div className="navigation-item__icon">
          <Icon data={icon} />
        </div>

        <div className="navigation-item__label">
          <span>{intl.formatMessage({id: label, defaultMessage: label})}</span>
        </div>

        {showCounter && (
          <span className="navigation-item__counter">{counter}</span>
        )}
      </NavLink>
    );
  }
}

export default NavigationItem;
