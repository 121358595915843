// Import redux and midleware
import {createStore, applyMiddleware, compose} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

// Import browser history
import {createBrowserHistory} from 'history';

// Import combined reducer
import reducers from './reducers';

// Create browser history state
export const history = createBrowserHistory();

// Initial state for store
const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

// Push developer tool if env is development
if ((process.env.REACT_APP_ENV as string).trim() === 'development') {
  const devToolsExtension: any = (window as any)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// apply middleware and enhancers
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

// Create redux store
const finalReducer = reducers(history);
export const store = createStore(finalReducer, initialState, composedEnhancers);
