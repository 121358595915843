import {storage} from '../../utils';
import {PT} from '../../config/constant';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';

/**
 * wrap component to make it accessable only if user is authenticated
 * storage.isTokenExist should return true
 */
const isEmergencyMessageCreator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isEmergencyMessageCreator
      : false;
    return isAllowed;
  },
});
const isEmergencyMessageApprover: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isEmergencyMessageApprover
      : false;
    return isAllowed;
  },
});
const canAccessBroadcast: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isSafetyBroadcastMessageCreator = profile
      ? JSON.parse(profile).isSafetyBroadcastMessageCreator
      : false;
    const isSafetyBroadcastMessageApprover = profile
      ? JSON.parse(profile).isSafetyBroadcastMessageApprover
      : false;
    const isManagementBroadcastMessageCreator = profile
      ? JSON.parse(profile).isManagementBroadcastMessageCreator
      : false;
    const isManagementBroadcastMessageApprover = profile
      ? JSON.parse(profile).isManagementBroadcastMessageApprover
      : false;

    return (
      isSafetyBroadcastMessageCreator ||
      isSafetyBroadcastMessageApprover ||
      isManagementBroadcastMessageCreator ||
      isManagementBroadcastMessageApprover
    );
  },
});
const isBroadcastMessageCreator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isSafetyBroadcastMessageCreator = profile
      ? JSON.parse(profile).isSafetyBroadcastMessageCreator
      : false;
    // const isSafetyBroadcastMessageApprover = profile ? JSON.parse(profile).isSafetyBroadcastMessageApprover : false
    const isManagementBroadcastMessageCreator = profile
      ? JSON.parse(profile).isManagementBroadcastMessageCreator
      : false;
    // const isManagementBroadcastMessageApprover = profile ? JSON.parse(profile).isManagementBroadcastMessageApprover : false

    return (
      isSafetyBroadcastMessageCreator || isManagementBroadcastMessageCreator
    );
  },
});
const isSafetyBroadcastMessageCreator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isSafetyBroadcastMessageCreator
      : false;
    return isAllowed;
  },
});
const isSafetyBroadcastMessageApprover: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isSafetyBroadcastMessageApprover
      : false;
    return isAllowed;
  },
});
const isManagementBroadcastMessageCreator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isManagementBroadcastMessageCreator
      : false;
    return isAllowed;
  },
});
const isManagementBroadcastMessageApprover: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isManagementBroadcastMessageApprover
      : false;
    return isAllowed;
  },
});
const isAttendanceReportViewer: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isAttendanceReportViewer
      : false;
    return isAllowed;
  },
});

const canAccessReport: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAttendanceReportViewer = profile
      ? JSON.parse(profile).isAttendanceReportViewer
      : false;
    const isAttendanceReportCreator = profile
      ? JSON.parse(profile).isAttendanceReportCreator
      : false;

    return isAttendanceReportViewer || isAttendanceReportCreator;
  },
});

const canAccessDashboard: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isDashboardMenuViewer = profile
      ? JSON.parse(profile).isDashboardMenuViewer
      : false;

    return isDashboardMenuViewer;
  },
});

const isEmployeeAccountAdministrator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isEmployeeAccountAdministrator
      : false;
    return isAllowed;
  },
});
const isApprovalAdministrator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isApprovalAdministrator
      : false;
    return isAllowed;
  },
});
const isAdminUserAdministrator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isAdminUserAdministrator
      : false;
    return isAllowed;
  },
});

const isHolidayAdministrator: any = connectedRouterRedirect({
  redirectPath: PT.DASHBOARD,
  authenticatedSelector: () => {
    const profile = storage.getProfile();
    const isAllowed = profile
      ? JSON.parse(profile).isHolidayAdministrator
      : false;
    return isAllowed;
  },
});

export default {
  isEmergencyMessageCreator,
  isEmergencyMessageApprover,
  isSafetyBroadcastMessageCreator,
  isSafetyBroadcastMessageApprover,
  isManagementBroadcastMessageCreator,
  isManagementBroadcastMessageApprover,
  isAttendanceReportViewer,
  isEmployeeAccountAdministrator,
  isApprovalAdministrator,
  isAdminUserAdministrator,
  canAccessBroadcast,
  isBroadcastMessageCreator,
  canAccessReport,
  canAccessDashboard,
};
