import {RESET, ATTENDANCEAPPROVALREPORTS} from '../../definitions';
import {settings} from '../../../config/config';
import {cookie} from '../../../utils';

const getSetting = cookie.getAppFilter();
const initialSetting = getSetting
  ? JSON.parse(getSetting)
  : settings.filterReport;

const initialState = {
  data: [],
  data_detail: [],
  isLoading: false,
  filter: {
    start: initialSetting.start,
    end: initialSetting.end,
  },
  isLoadingFilter: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ATTENDANCEAPPROVALREPORTS.GET:
      return Object.assign(
        {},
        {
          ...state,
          data: action.data,
          data_detail: [],
        },
      );

    case ATTENDANCEAPPROVALREPORTS.GET_DETAIL:
      return Object.assign(
        {},
        {
          ...state,
          data_detail: action.data,
        },
      );

    case ATTENDANCEAPPROVALREPORTS.FILTER:
      return Object.assign(
        {},
        {
          ...state,
          filter: action.data,
        },
      );

    case ATTENDANCEAPPROVALREPORTS.FILTER_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoadingFilter: action.isLoadingFilter,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
