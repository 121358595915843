import {
  JOB_POSITION_CREATE_FETCH,
  JOB_POSITION_CREATE_SUCCESS,
  JOB_POSITION_CREATE_FAILED,
  JOB_POSITION_UPDATE_FETCH,
  JOB_POSITION_UPDATE_SUCCESS,
  JOB_POSITION_UPDATE_FAILED,
  RESET,
} from '../../definitions';
import {
  JobPositionInitialState,
  JobPositionAction,
} from '../../../config/interface';

const initialState: JobPositionInitialState = {
  jobPositionCreateLoading: false,
  jobPositionUpdateLoading: false,
  action: '',
};

//#region REDUCER
function jobPositionReducer(
  state = initialState,
  action: JobPositionAction,
): JobPositionInitialState {
  const actions = {
    //#region job position update
    [JOB_POSITION_CREATE_FETCH]: (): JobPositionInitialState => ({
      ...state,
      jobPositionCreateLoading: true,
      action: action.type,
    }),
    [JOB_POSITION_CREATE_SUCCESS]: (): JobPositionInitialState => ({
      ...state,
      jobPositionCreateLoading: false,
      action: action.type,
    }),
    [JOB_POSITION_CREATE_FAILED]: (): JobPositionInitialState => ({
      ...state,
      jobPositionCreateLoading: false,
      action: action.type,
    }),
    //#endregion
    //#region job position update
    [JOB_POSITION_UPDATE_FETCH]: (): JobPositionInitialState => ({
      ...state,
      jobPositionUpdateLoading: true,
      action: action.type,
    }),
    [JOB_POSITION_UPDATE_SUCCESS]: (): JobPositionInitialState => ({
      ...state,
      jobPositionUpdateLoading: false,
      action: action.type,
    }),
    [JOB_POSITION_UPDATE_FAILED]: (): JobPositionInitialState => ({
      ...state,
      jobPositionUpdateLoading: false,
      action: action.type,
    }),
    //#endregion
    [RESET.ALL]: (): JobPositionInitialState => initialState,
    DEFAULT: (): JobPositionInitialState => state,
  };

  return (actions[action.type] || actions.DEFAULT)();
}

//#endregion

export default jobPositionReducer;
