import {CONF, RESET} from '../../definitions';
import {storage} from '../../../utils';
import {settings} from '../../../config/config';
import {
  ConfigInitialState,
  ConfigAction,
  ConfigSetLang,
} from '../../../config/interface';

const initialState: ConfigInitialState = {
  lang: storage.getLang() || settings.lang,
};

export default (state = initialState, action: ConfigAction) => {
  switch (action.type) {
    case CONF.LANG:
      return Object.assign(
        {},
        {
          lang: (action as ConfigSetLang).lang,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
