import React from 'react';
import {connect} from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import {PT} from '../../config/constant';
import {IntlProvider} from 'react-intl';
import {
  isAuthenticated,
  isNotAuthenticated,
  isHavePriviledge,
} from '../../policies';
import {messages} from '../../lang';
import {storage} from '../../utils';
import {bindActionCreators, Dispatch} from 'redux';
import {LANG} from '../../config/constant';
import {Container, Spinner} from '../../components/common';
import {getProfile} from '../../store/actions/profile';
import {getMaster} from '../../store/actions/master';
import NavigationBar from '../NavigationBar';
import Loadable from 'react-loadable';
import moment from 'moment';
import Alert from '../Alert';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {AppState} from '../../config/interface';
import './_index.scss';

interface LoadingProp {
  pastDelay?: boolean;
}
const Loading = (props: LoadingProp) => {
  if (props.pastDelay) {
    return <Spinner />;
  } else {
    return null;
  }
};

const loadComponent = (name: string) => {
  return Loadable({
    loader: () => import('../' + name),
    loading: Loading,
    delay: 100,
  });
};

interface Prop {
  lang: LANG;
  isSignedIn: boolean;
  getProfile(): void;
  getMaster(): void;
}

export class Wrapper extends React.Component<Prop> {
  constructor(props: Prop) {
    super(props);
  }

  componentDidMount() {
    if (storage.isTokenExist()) {
      this.props.getProfile();
      this.props.getMaster();
    }
  }

  componentDidUpdate() {}

  render() {
    const {lang, isSignedIn} = this.props;

    // set locale on render
    moment.locale(lang);

    return (
      <IntlProvider locale={lang} messages={messages[lang]}>
        <>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={lang}>
            <Container
              className="wrapper"
              isSidebarOnDesktop
              isFull={!isSignedIn}
            >
              <Router>
                <>
                  {isSignedIn && <NavigationBar />}
                  <Switch>
                    <Route
                      exact
                      path={PT.SIGNIN}
                      component={isNotAuthenticated(loadComponent('Signin'))}
                    />
                    <Route
                      path={PT.ATTDETAILREPORT}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.canAccessReport(fn);
                        })(loadComponent('AttendanceDetailReport')),
                      )}
                    />
                    <Route
                      path={PT.ATTSUMMARYREPORT}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.canAccessReport(fn);
                        })(loadComponent('AttendanceSummaryReport')),
                      )}
                    />
                    <Route
                      path={PT.DASHBOARD}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.canAccessDashboard(fn);
                        })(loadComponent('Dashboard')),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMPLOYEE}
                      component={isAuthenticated(loadComponent('Employee'))}
                    />
                    <Route
                      exact
                      path={PT.EMPLOYEEADD}
                      component={isAuthenticated(
                        loadComponent('EmployeeForm/create'),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMPLOYEEEDIT}
                      component={isAuthenticated(
                        loadComponent('EmployeeForm/edit'),
                      )}
                    />
                    <Route
                      exact
                      path={PT.APPROVALSETTINGS}
                      component={isAuthenticated(
                        isHavePriviledge.isApprovalAdministrator(
                          loadComponent('ApprovalSettings'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.ADMINUSER}
                      component={isAuthenticated(
                        isHavePriviledge.isAdminUserAdministrator(
                          loadComponent('AdminUsers'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMERGENCY}
                      component={isAuthenticated(
                        isHavePriviledge.isEmergencyMessageCreator(
                          loadComponent('Emergency'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMERGENCYADD}
                      component={isAuthenticated(
                        isHavePriviledge.isEmergencyMessageCreator(
                          loadComponent('EmergencyForm'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMERGENCYEDIT}
                      component={isAuthenticated(
                        isHavePriviledge.isEmergencyMessageCreator(
                          loadComponent('EmergencyEdit'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.EMERGENCYDETAIL}
                      component={isAuthenticated(
                        isHavePriviledge.isEmergencyMessageCreator(
                          loadComponent('EmergencyDetail'),
                        ),
                      )}
                    />
                    <Route
                      exact
                      path={PT.SETTING}
                      component={isAuthenticated(loadComponent('Setting'))}
                    />
                    <Route
                      exact
                      path={PT.BROADCASTADD}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.isBroadcastMessageCreator(fn);
                        })(loadComponent('BroadcastForm/create')),
                      )}
                    />
                    <Route
                      exact
                      path={PT.BROADCASTEDIT}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.isBroadcastMessageCreator(fn);
                        })(loadComponent('BroadcastForm/edit')),
                      )}
                    />
                    <Route
                      path={PT.BROADCASTDETAIL}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.canAccessBroadcast(fn);
                        })(loadComponent('BroadcastDetail')),
                      )}
                    />
                    <Route
                      path={PT.BROADCAST}
                      component={isAuthenticated(
                        ((fn: Function) => {
                          return isHavePriviledge.canAccessBroadcast(fn);
                        })(loadComponent('Broadcast')),
                      )}
                    />
                    <Route
                      exact
                      path={PT.MASTER}
                      component={isAuthenticated(loadComponent('Master'))}
                    />
                    <Redirect to={PT.SIGNIN} />
                  </Switch>
                </>
              </Router>
            </Container>
            <Alert />
          </MuiPickersUtilsProvider>
        </>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  lang: state.config.lang,
  isSignedIn: !!state.profile.data.id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProfile,
      getMaster,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
