import {POSITION, RESET} from '../../definitions';

const initialState = {
  postPositionLoading: false,
  patchPositionLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case POSITION.PATCH_POSITION_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          patchPositionLoading: action.isLoading,
        },
      );

    case POSITION.POST_POSITION_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          postPositionLoading: action.isLoading,
        },
      );

    case RESET.ALL:
      return initialState;

    default:
      return state;
  }
};
