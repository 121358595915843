import React, {ReactElement} from 'react';
import './_index.scss';

interface Prop {
  className?: string;
  data: string | ReactElement;
}

class Icon extends React.Component<Prop> {
  render() {
    const {data} = this.props;

    return (
      <>
        {typeof data === 'string' ? (
          <i className="material-icons">{data}</i>
        ) : (
          data
        )}
      </>
    );
  }
}

export default Icon;
