import {settings} from '../../../config/config';
import {cookie} from '../../../utils';
import {EMERGENCY, RESET} from '../../definitions';
import moment from 'moment';

const getSetting = cookie.getAppFilter();
const initialSetting = getSetting
  ? JSON.parse(getSetting)
  : settings.filterReport;

interface EmergencyState {
  isLoading: boolean;
  detail: {};
  filter?: {};
  isLoadingFilter: boolean;
  isApproveLoading: boolean;
  isCancelPublishLoading: boolean;
  isRejectLoading: boolean;
  isRevisionLoading: boolean;
}

const initialState: EmergencyState = {
  isLoading: false,
  isLoadingFilter: false,
  detail: {},
  filter: {
    start: moment().startOf('day').subtract(1, 'month').toDate(), //.startOf('day').subtract(1, 'month').toDate(),
    end: moment().toDate(),
  },
  isApproveLoading: false,
  isCancelPublishLoading: false,
  isRejectLoading: false,
  isRevisionLoading: false,
};

export default (state: EmergencyState = initialState, action: any) => {
  const {type, data} = action;
  switch (type) {
    case EMERGENCY.GET_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: action.isLoading,
        },
      );
    case EMERGENCY.LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoading: action.isLoading,
        },
      );
    case EMERGENCY.DETAIL:
      return Object.assign(
        {},
        {
          ...state,
          detail: data,
        },
      );
    case EMERGENCY.FILTER_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isLoadingFilter: action.isLoadingFilter,
        },
      );
    case EMERGENCY.FILTER:
      return Object.assign(
        {},
        {
          ...state,
          filter: data,
        },
      );
    case EMERGENCY.APPROVE_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isApproveLoading: action.isApproveLoading,
        },
      );
    case EMERGENCY.APPROVE:
      return Object.assign(
        {},
        {
          ...state,
        },
      );
    case EMERGENCY.CANCEL_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isCancelPublishLoading: action.isCancelPublishLoading,
        },
      );
    case EMERGENCY.CANCEL:
      return Object.assign(
        {},
        {
          ...state,
        },
      );
    case EMERGENCY.REJECT_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isRejectLoading: action.isRejectLoading,
        },
      );
    case EMERGENCY.REJECT:
      return Object.assign(
        {},
        {
          ...state,
        },
      );
    case EMERGENCY.REVISION_LOADING:
      return Object.assign(
        {},
        {
          ...state,
          isRevisionLoading: action.isRevisionLoading,
        },
      );
    case EMERGENCY.REVISION:
      return Object.assign(
        {},
        {
          ...state,
        },
      );
    case RESET.ALL:
      return initialState;
    default:
      return state;
  }
};
