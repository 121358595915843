import {Dispatch} from 'redux';
import {AxiosError} from 'axios';
import {PROF, ALERT} from '../../definitions';
import {storage, alert, logger} from '../../../utils';
import api from '../../../api';
import {AppState, IProfileRes, IAdminRolesRes} from '../../../config/interface';
import {PRIVILEDGE} from '../../../config/constant';
import {initUserLog} from '../../../utils/logger';
import {signout} from '../signout';

const getProfile = () => {
  return (dispatch: Dispatch) => {
    dispatch({type: PROF.GET_LOADING, isLoading: true});

    // Dispatch temporary data from localstorage
    let tempProfile = storage.getProfile();
    if (tempProfile) {
      dispatch({
        type: PROF.GET,
        data: JSON.parse(tempProfile),
      });
    }

    // Api call
    api
      .getProfile()
      .then((result: IProfileRes) => {
        let profile = {
          id: result.data.id,
          name: result.data.name,
          employeeCode: result.data.employee_code,
          isApprover: result.data.is_approver,
          status: result.data.status,
          isEmergencyMessageCreator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.EMERGENCY_MESSAGE_CREATOR,
            ) >= 0,
          isEmergencyMessageApprover:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.EMERGENCY_MESSAGE_APPROVER,
            ) >= 0,
          isSafetyBroadcastMessageCreator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.SAFETY_BROADCAST_MESSAGE_CREATOR,
            ) >= 0,
          isSafetyBroadcastMessageApprover:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.SAFETY_BROADCAST_MESSAGE_APPROVER,
            ) >= 0,
          isManagementBroadcastMessageCreator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.MANAGEMENT_BROADCAST_MESSAGE_CREATOR,
            ) >= 0,
          isManagementBroadcastMessageApprover:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.MANAGEMENT_BROADCAST_MESSAGE_APPROVER,
            ) >= 0,
          isAttendanceReportCreator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.ATTENDANCE_REPORT_CREATOR,
            ) >= 0,
          isAttendanceReportViewer:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.ATTENDANCE_REPORT_VIEWER,
            ) >= 0,
          isEmployeeAccountAdministrator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.EMPLOYEE_ACCOUNT_ADMINISTRATOR,
            ) >= 0,
          isApprovalAdministrator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.APPROVAL_ADMINISTRATOR,
            ) >= 0,
          isAdminUserAdministrator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.ADMIN_USER_ADMINISTRATOR,
            ) >= 0,
          isHolidayAdministrator:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.HOLIDAY_ADMINISTRATOR,
            ) >= 0,
          isDashboardMenuViewer:
            result.data.admin_roles.findIndex(
              (i: IAdminRolesRes) =>
                i.role_id == PRIVILEDGE.DASHBOARD_MENU_VIEWER,
            ) >= 0,
        };

        storage.setProfile(profile);
        initUserLog(profile);

        return dispatch({
          type: PROF.GET,
          data: profile,
        });
      })
      .then(() => dispatch({type: PROF.GET_LOADING, isLoading: false}))
      .catch((err) => {
        logger(
          err,
          'error',
          'getProfile',
          false,
          err && err.response && err.response.data,
        );
        dispatch({type: PROF.GET_LOADING, isLoading: false});
      });
  };
};

const changePassword = () => {
  return (dispatch: any, getState: () => AppState) => {
    return new Promise((resolve, reject) => {
      dispatch({type: PROF.CHANGE_PASS_LOADING, isChanging: true});

      const {form} = getState();

      api
        .changePassword({
          old_password: form.changePassword.values.old_password,
          new_password: form.changePassword.values.new_password,
        })
        .then(() => {
          dispatch(signout());
          dispatch({type: ALERT.SHOW, alert: alert(200, 'changePassword')});
          dispatch({type: PROF.CHANGE_PASS_LOADING, isChanging: false});
          resolve();
        })
        .catch((err: AxiosError) => {
          dispatch({type: PROF.CHANGE_PASS_LOADING, isChanging: false});
          dispatch({
            type: ALERT.SHOW,
            alert:
              err && err.response
                ? alert(
                    err.response.status,
                    'changePassword',
                    err.response.data && err.response.data.error
                      ? err.response.data.error.code
                      : null,
                  )
                : alert(0, 'changePassword'),
          });
          logger(
            err,
            'error',
            'changePassword',
            false,
            err && err.response && err.response.data,
          );
          reject();
        });
    });
  };
};

export {getProfile, changePassword};
