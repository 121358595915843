import React from 'react';
import './_index.scss';

interface IProp {
  className?: string;
  value: any;
  onChange: any;
}

interface IState {}

class Toggle extends React.Component<IProp, IState> {
  constructor(props: IProp) {
    super(props);
  }

  render() {
    const {value, onChange} = this.props;
    return (
      <label className="switch">
        <input type="checkbox" value={value} onChange={onChange} />
        <div className="slider">
          <div className="round" />
        </div>
      </label>
    );
  }
}

export default Toggle;
