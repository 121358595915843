import {ALERT} from '../../definitions';
import {Dispatch} from 'redux';

const showAlert = (alert: object) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ALERT.SHOW,
      alert,
    });
  };
};

const hideAlert = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ALERT.HIDE,
    });
  };
};

export {showAlert, hideAlert};
